<template>
  <v-dialog v-model="modalData.dialog" persistent min-width="1650" scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="mb-2 ml-4"
        v-bind="attrs"
        v-on="on"
        @click="handleNew"
      >
        New Price list
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }}</span>
            <v-btn
              @click="handleCloseModalForm"
              style="float: right; cursor: pointer"
              icon
              color="#26223c"
            >
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text style="max-height: 90%">
        <v-form v-model="formValid" ref="form">
          <v-row>
            <v-col cols="12" sm="2" md="2">
              <v-btn
                depressed
                color="primary"
                class="ma-2"
                v-if="!clonePriceList && formModel.id"
                @click="handleClonePriceList"
              >
                Clone price list
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="2" md="2">
              <v-select
                v-if="statuses.inventory"
                v-model="formModel.status"
                :items="statuses.inventory.priceList"
                :id="dynamicID"
                label="Status"
                item-text="value"
                item-value="key"
              />
            </v-col>
          </v-row>
          <v-container>
            <v-row>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-model="formModel.name"
                  label="Price list name"
                  :rules="nameRules"
                  :id="dynamicID"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-select
                  :disabled="currencyDisabled"
                  v-model="formModel.currency_id"
                  :items="currencyCollection"
                  item-text="sign"
                  item-value="id"
                  label="Currency"
                  :rules="currencyRules"
                  :id="dynamicID"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  :disabled="clonePriceList"
                  v-model="formModel.vat_name"
                  label="Default tax name"
                  :id="dynamicID"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  :disabled="clonePriceList"
                  v-model="formModel.vat_value"
                  label="Default tax rate"
                  :id="dynamicID"
                >
                  <v-icon slot="append" color="grey"> mdi-percent </v-icon>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-card>
                  <v-card-title>
                    <v-form v-model="formItemDataValid" ref="formItemData">
                      <v-row> List of items on this price list</v-row>
                      <v-row>
                        <v-col cols="12" sm="2" md="2">
                          <v-autocomplete
                            v-model="itemInputData.item"
                            :items="filteredItemCollection"
                            item-text="ReferenceName"
                            label="Search item"
                            return-object
                            :disabled="isEditedItemData"
                            :rules="requiredRules"
                            :change="handleChangeItem(itemInputData.item)"
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="1" md="1">
                          <v-text-field
                            v-model="itemInputData.currency_id"
                            label="Currency"
                            disabled
                            :id="dynamicID"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="1" md="1">
                          <div class="d-flex flex-row">
                            <div>
                              <!-- :items="itemInputData.item.default_quantities" -->
                              <v-select
                                v-if="itemInputData.item"
                                v-model="itemInputData.quantity"
                                :items="computedQuantities"
                                label="Quantity"
                                :rules="requiredRules"
                                :id="dynamicID"
                                :disabled="isEditedItemData"
                              />
                            </div>

                            <div class="pa-1 mt-4" style="white-space: nowrap">
                              {{ itemInputData.quantityUnitTranslated }}
                            </div>
                          </div>
                        </v-col>

                        <v-col cols="12" sm="1" md="1">
                          <v-text-field
                            v-model="itemInputData.price_net"
                            label="Item price net"
                            type="number"
                            :rules="priceRules"
                            v-on:input="handleCalculateBrutto"
                            :id="dynamicID"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="1" md="1">
                          <v-text-field
                            v-model="itemInputData.vat_name"
                            label="Tax name"
                            :id="dynamicID"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2" md="2">
                          <v-text-field
                            v-model="itemInputData.vat_value"
                            label="Tax rate"
                            v-on:input="handleCalculateBrutto"
                            type="number"
                            min="0"
                            :id="dynamicID"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="2" md="2">
                          <v-text-field
                            v-model="itemInputData.price_gross"
                            label="Item price gross"
                            type="number"
                            :rules="priceRules"
                            v-on:input="handleCalculateNetto"
                            :id="dynamicID"
                          ></v-text-field>
                        </v-col>
                        <!-- <v-col cols="12" sm="2" md="2">
                          <v-text-field
                            :id="dynamicID"
                            v-model="itemInputData.landing_url"
                            label="Landing URL"
                          ></v-text-field>
                        </v-col> -->
                        <v-col cols="12" sm="2" md="2">
                          <v-btn
                            color="success"
                            @click="handleAddItemData"
                            class="ma-2"
                          >
                            Add
                          </v-btn>
                          <v-btn
                            class="ma-2"
                            @click="handleCancelItemData"
                            :disabled="!cancelItemData"
                          >
                            Cancel
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                      :id="dynamicID"
                    ></v-text-field>
                  </v-card-title>

                  <v-data-table
                    :headers="headers"
                    :items="formModel.items"
                    :search="search"
                    @click:row="handleClick"
                  >
                    <template v-slot:item.price_net="{ item }">
                      <span v-if="inlineEdit.item_id != item.item_id">
                        {{
                          $helpers.getNicePrice(
                            item.price_net,
                            itemInputData.currency_id
                          )
                        }}
                      </span>
                      <v-text-field
                        v-else
                        label="Item price net"
                        v-model="inlineEdit.price_net"
                        class="inline-input"
                        :rules="priceRules"
                        v-on:input="handleCalculateBruttoInline"
                      ></v-text-field>
                    </template>
                    <template v-slot:item.price_gross="{ item }">
                      <span v-if="inlineEdit.item_id != item.item_id">
                        {{
                          $helpers.getNicePrice(
                            item.price_gross,
                            itemInputData.currency_id
                          )
                        }}
                      </span>
                      <v-text-field
                        v-else
                        label="Item price gross"
                        v-model="inlineEdit.price_gross"
                        class="inline-input"
                        :rules="priceRules"
                        v-on:input="handleCalculateNettoInline"
                      ></v-text-field>
                    </template>
                    <!-- <template v-slot:item.url="{ item }">
                      <span v-if="inlineEdit.item_id != item.item_id">
                        <a :href="item.landing_url" target="_blank">{{
                          item.landing_url
                        }}</a>
                      </span>
                      <v-text-field
                        v-else
                        label="Landing URL"
                        class="inline-input"
                        v-model="inlineEdit.landing_url"
                        dense
                      ></v-text-field>
                    </template> -->
                    <template v-slot:item.vat_name="{ item }">
                      <span v-if="inlineEdit.item_id != item.item_id">
                        {{ item.vat_name }}
                      </span>
                      <v-text-field
                        v-else
                        label="Tax name"
                        class="inline-input"
                        v-model="inlineEdit.vat_name"
                        dense
                      ></v-text-field>
                    </template>
                    <template v-slot:item.vat_value="{ item }">
                      <span v-if="inlineEdit.item_id != item.item_id">
                        {{ item.vat_value }}
                      </span>
                      <v-text-field
                        v-else
                        label="Tax value"
                        v-model="inlineEdit.vat_value"
                        class="inline-input"
                        dense
                        :rules="vat_valueRules"
                      ></v-text-field>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <!-- <v-icon
                        small
                        class="mr-2"
                        @click="handleEditItem(Object.assign({}, item))"
                        :disabled="isEditedItemData"
                      >
                        mdi-pencil
                      </v-icon> -->
                      <v-icon small @click="handleDeleteItem(item)">
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="handleCloseModalForm">
          Cancel
        </v-btn>

        <v-btn
          color="blue darken-1"
          text
          @click="handleSaveModalForm"
          v-if="
            (permissionCan('create') && !this.editedItem) ||
            permissionCan('update')
          "
        >
          Save
        </v-btn>
      </v-card-actions>
      <!-- <pre>
        {{ formModel.items }}
      </pre> -->
      <v-dialog v-model="dialogDeleteItem" max-width="500px">
        <v-card>
          <v-card-title class="headline"
            >Are you sure you want to delete this item?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDeleteItem"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- <pre>
        {{ formModel.items }}
      </pre> -->
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import i18nService from "@/core/services/i18n.service.js";

const TRANSLATED_ATRIBUTES = [
  // "name"
];

export const initialFormData = () => ({
  id: null,
  status: 1,
  name: "",
  vat_value: "27",
  vat_name: "",
  currency_id: null,
  items: [],
  translations: {},
});

export const initialItemInputData = {
  item_id: null,
  item: {},
  name: "",
  reference: "",
  quantityUnitTranslated: "",
  currency_id: null,
  price_net: 0,
  vat_name: null,
  vat_value: null,
  price_gross: 0,
  landing_url: null,
  quantity: null,
};

export const initialInlineEdit = {
  item_id: null,
  vat_name: null,
  vat_value: 0,
  price_gross: 0,
  price_net: 0,
  landing_url: null,
};

export default {
  name: "PriceListModalForm",
  props: [
    "modalData",
    "statuses",
    "currencyCollection",
    "itemCollection",
    "permissions",
    "loader",
  ],
  data() {
    return {
      items: ["1", "2", "3"],
      languages: i18nService.languages,
      selectedLocale: i18nService.languages[0],
      formModel: Object.assign({}, initialFormData()),
      formValid: false,
      clonePriceList: false,
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length > 2 || "Name must be min 3 characters",
      ],
      vat_nameRules: [
        (v) => v.length > 2 || "Default tax name must be min 3 characters",
      ],
      vat_valueRules: [
        (v) => !!v || "Default tax rate is required",
        (v) => (v >= 0 && v < 100) || "The value must be between 0 and 99.99",
        // (v) => v.length < 6 || "The value must be max 5 characters",
      ],
      priceRules: [
        // (v) => !!v || "Price is required",
        (v) => v >= -1 || "The value must be min 0",
      ],
      requiredRules: [(v) => !!v || "Field is required"],

      currencyRules: [(v) => !!v || "Field is required"],

      search: "",
      headers: [
        {
          text: "Reference",
          value: "reference",
        },
        {
          text: "Quantity",
          // filterable: false,
          value: "quantity",
        },
        {
          text: "Quantity unit",
          // filterable: false,
          value: "quantityUnitTranslated",
        },
        {
          text: "Item name",
          // filterable: false,
          value: "name",
        },
        {
          text: "Item price net",
          value: "price_net",
          filterable: false,
        },
        {
          text: "Tax name",
          value: "vat_name",
          width: "80px",
          filterable: false,
        },
        {
          text: "Tax rate",
          value: "vat_value",
          width: "80px",
          filterable: false,
        },
        {
          text: "Item price gross",
          value: "price_gross",
          filterable: false,
        },
        // {
        //   text: "Landing URL",
        //   value: "url",
        //   filterable: false,
        // },
        {
          text: "Actions",
          value: "actions",
          filterable: false,
        },
      ],

      formItemDataValid: false,
      formItemData: false,
      itemInputData: Object.assign({}, initialItemInputData),
      cancelItemData: null,
      isEditedItemData: false,
      itemTodelete: {},
      dialogDeleteItem: false,
      inlineEdit: Object.assign({}, initialInlineEdit),
    };
  },
  computed: {
    ...mapGetters(["quantityUnitCollection"]),
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },
    currencyDisabled() {
      if (this.formModel) {
        return this.formModel.id ? 1 : 0;
      } else {
        return 0;
      }
    },
    formTitle() {
      if (this.clonePriceList) return "Clone price list";
      return !this.formModel.id ? "New price list" : "Edit price list";
    },

    filteredItemCollection() {
      let itemIds = this.formModel.items.map((item) => item.item_id);

      var counts = {};
      itemIds.forEach(function (x) {
        counts[x] = (counts[x] || 0) + 1;
      });
      return this.itemCollection.filter((item) => {
        return item.default_quantities.length != counts[item.id];
      });
    },

    computedQuantities() {
      if (!this.itemInputData.item || !this.itemInputData.item.id) return [];
      let quantities = this.itemInputData.item.default_quantities || [];
      let usedQuantities = this.formModel.items
        .filter((i) => {
          return i.item_id == this.itemInputData.item.id;
        })
        .map((i) => {
          return 1 * i.quantity;
        });

      return quantities.filter((q) => {
        return !usedQuantities.includes(1 * q);
      });
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.formModel = value.editedItem;
      },
    },

    formModel: {
      deep: true,
      handler(value) {
        this.itemInputData.currency_id = value.currency_id;
        if (!this.isEditedItemData) {
          this.itemInputData.vat_name = value.vat_name;
          this.itemInputData.vat_value = value.vat_value;
        }
      },
    },
  },
  methods: {
    ...mapActions(["fetchQuantityUnit"]),
    handleCloseModalForm() {
      this.inlineEdit = Object.assign({}, initialInlineEdit);
      this.clonePriceList = false;
      this.itemInputData = Object.assign({}, initialItemInputData);
      this.cancelItemData = null;
      this.isEditedItemData = false;
      this.$emit("closeModalForm");
    },
    handleSaveModalForm() {
      this.$refs.form.validate();

      if (this.formValid) {
        this.clonePriceList = false;
        this.inlineEdit = Object.assign({}, initialInlineEdit);
        this.$emit("saveModalForm", Object.assign({}, this.formModel));
      }
    },
    setTranslatedAttributes(newItem = false) {
      if (newItem) {
        this.languages.forEach((language) => {
          TRANSLATED_ATRIBUTES.forEach((attribute) => {
            this.formModel.translations[language.lang] = {};
            this.formModel.translations[language.lang][attribute] = "";
          });
        });
      } else {
        this.languages.forEach((language) => {
          TRANSLATED_ATRIBUTES.forEach((attribute) => {
            if (!this.formModel.translations[language.lang]) {
              this.formModel.translations[language.lang] = {};
              this.formModel.translations[language.lang][attribute] = "";
            }
          });
        });
      }
    },

    handleCalculateBrutto() {
      if (this.itemInputData.price_net) {
        this.itemInputData.price_gross = this.getFormattedPrice(
          Number(this.itemInputData.price_net) *
            (Number(this.itemInputData.vat_value) / 100 + 1)
        );
      }
    },

    handleCalculateNetto() {
      if (this.itemInputData.price_gross) {
        this.itemInputData.price_net = this.getFormattedPrice(
          Number(this.itemInputData.price_gross) /
            (Number(this.itemInputData.vat_value) / 100 + 1)
        );
      }
    },

    handleCalculateBruttoInline() {
      if (this.inlineEdit.price_net) {
        this.inlineEdit.price_gross =
          Number(this.inlineEdit.price_net) *
            (Number(this.inlineEdit.vat_value) / 100 + 1) ?? 0;
      } else {
        console.log("this.inlineEdit.price_gross", this.inlineEdit.price_gross);
        this.inlineEdit.price_gross = 0;
      }
    },

    handleCalculateNettoInline() {
      if (this.inlineEdit.price_gross) {
        this.inlineEdit.price_net =
          Number(this.inlineEdit.price_gross) /
            (Number(this.inlineEdit.vat_value) / 100 + 1) ?? 0;
      } else {
        console.log("this.inlineEdit.price_net", this.inlineEdit.price_net);
        this.inlineEdit.price_net = 0;
      }
    },

    getFormattedPrice(price, currency = this.itemInputData.currency_id) {
      if (price == 0 || !price || isNaN(price)) return 0;

      if (currency == "HUF") {
        return Math.round(price);
      }

      return (1 * price).toFixed(2) ?? 0;
    },

    handleNew() {
      this.clonePriceList = false;
      if (this.$refs.formItemData) {
        this.$refs.formItemData.resetValidation();
      }
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
      //   this.setTranslatedAttributes(true);
    },

    handleAddItemData() {
      this.$refs.formItemData.validate();
      if (this.formItemDataValid) {
        // this.itemInputData.price_gross = this.getFormattedPrice(
        //   this.itemInputData.price_gross
        // );

        // this.itemInputData.price_net = this.getFormattedPrice(
        //   this.itemInputData.price_net
        // );
        this.itemInputData.item_id = this.itemInputData.item.id;
        this.itemInputData.name = this.itemInputData.item.name;
        this.itemInputData.reference = this.itemInputData.item.reference;
        if (!this.itemInputData.landing_url)
          this.itemInputData.landing_url = "";
        this.formModel.items.unshift(Object.assign({}, this.itemInputData));
        this.itemInputData = Object.assign({}, this.initialItemInputData);
        this.$refs.formItemData.resetValidation();
        this.isEditedItemData = false;
        this.cancelItemData = null;
      }
    },

    handleCancelItemData() {
      this.formModel.items.unshift(Object.assign({}, this.cancelItemData));
      this.itemInputData = Object.assign({}, this.initialItemInputData);
      this.$refs.formItemData.resetValidation();
      this.cancelItemData = null;
      this.isEditedItemData = false;
    },

    handleEditItem(item) {
      if (!this.isEditedItemData) {
        // let priceSelectedItemObject = this.itemCollection.find((it) => {
        //   return item.item_id == it.id;
        // });
        // item.item = Object.assign({}, priceSelectedItemObject);
        // item.vat_value = 1 * item.vat_value;

        /*
        item.price_gross = this.getFormattedPrice(
          1*item.price_gross
        );
        item.price_net = this.getFormattedPrice(
          1*item.price_net
        );
        item.vat_value = 1*item.vat_value

        item.item_id = item.item.id;
        item.name = item.item.name;
        item.reference = item.item.reference;
        if (!item.landing_url)
          item.landing_url = "";

        this.itemInputData = null
        this.itemInputData = Object.assign({}, item);
*/

        this.itemInputData = { ...item };
        this.cancelItemData = { ...item };
        this.formModel.items = this.formModel.items.filter((i) => {
          return item.price_list_item_id != i.price_list_item_id;
        });

        this.isEditedItemData = true;
      }
    },

    handleDeleteItem(item) {
      // this.itemTodelete = this.formModel.items.find(
      //   (i) => i.price_list_item_id == item.price_list_item_id
      // );
      this.itemTodelete = item;
      this.dialogDeleteItem = true;
    },

    deleteItemConfirm() {
      this.formModel.items.splice(
        this.formModel.items.indexOf(this.itemTodelete),
        1
      );

      this.closeDeleteItem();
    },

    closeDeleteItem() {
      this.itemTodelete = {};
      this.dialogDeleteItem = false;
    },

    handleClonePriceList() {
      this.formModel.id = null;
      this.formModel.name = "";
      this.clonePriceList = true;
    },

    handleChangeItem(quantities) {
      if (this.computedQuantities.length == 0) {
        this.itemInputData.quantity = null;
      } else {
        if (!this.itemInputData.quantity)
          // this.itemInputData.quantity = quantities.default_quantities[0];
          this.itemInputData.quantity = this.computedQuantities[0];
        let qu = this.$store.getters.getQuantityUnitByID(
          quantities.quantity_unit_id
        );

        if (typeof qu == "object") {
          this.itemInputData.quantityUnitTranslated = qu.name;
        }
      }
    },

    permissionCan(action) {
      return this.permissions.find(
        (x) => x.name == "inventory.priceList." + action
      );
    },

    handleClick(row) {
      if (this.inlineEdit.item_id) {
        this.$refs.form.validate();
        if (!this.formValid) {
          return false;
        }

        let index = this.formModel.items.indexOf(
          this.formModel.items.find((i) => i.item_id == this.inlineEdit.item_id)
        );

        if (index > -1) {
          let item = this.formModel.items[index];
          item.price_gross = this.inlineEdit.price_gross ?? 0;
          item.price_net = this.inlineEdit.price_net ?? 0;
          item.vat_name = this.inlineEdit.vat_name;
          item.vat_value = this.inlineEdit.vat_value;
          item.landing_url = this.inlineEdit.landing_url;

          this.$set(this.formModel.items, index, item);
        }
      }

      if (this.inlineEdit.item_id && this.inlineEdit.item_id != row.item_id) {
        this.inlineEdit = Object.assign({}, initialInlineEdit);
      } else {
        this.inlineEdit.item_id = row.item_id;
        this.inlineEdit.price_gross = row.price_gross ?? 0;
        this.inlineEdit.price_net = row.price_net ?? 0;
        this.inlineEdit.vat_name = row.vat_name;
        this.inlineEdit.vat_value = row.vat_value;
        this.inlineEdit.landing_url = row.landing_url;
      }
    },
  },
  async mounted() {
    this.fetchQuantityUnit();
  },
};
</script>

<style scoped>
.inline-input ::v-deep input {
  font-size: 0.7em;

  text-transform: capitalize;
}

.inline-input ::v-deep label {
  font-size: 0.7em;
}

.inline-input ::v-deep button {
  font-size: 0.7em;
}
</style>
